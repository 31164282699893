import { application } from "./application";

import PhoneInputController from "./phone_input_controller.js";
application.register("phone-input", PhoneInputController);

import UsernameTypeSelectController from "./username_type_selection_controller.js";
application.register("username-type-selection", UsernameTypeSelectController);

import fileUploadInputController from "./file_upload_input_controller.js";
application.register("file-upload-input", fileUploadInputController);

import CharacterCounterController from "./character_counter_controller.js";
application.register("character-counter", CharacterCounterController);
