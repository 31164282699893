// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./controllers";
import * as Popper from "@popperjs/core";
import "bootstrap";
import wowjs from "wowjs/dist/wow.min";
import "mmenu-js/dist/mmenu";
import Choices from "choices.js";
import "trix"
import "@rails/actiontext"
import * as bootstrap from "bootstrap"

globalThis.bootstrap = bootstrap

const wow = new wowjs.WOW({
  animateClass: "animate__animated",
});

const handlePreloader = () => {
  for (const el of Array.from(document.getElementsByClassName("preloader"))) {
    setTimeout(() => {
      el.style.transition = "opacity 0.5s";
      el.style.opacity = 0;

      setTimeout(() => {
        el.style.display = "none";
      }, 500);
    }, 200);
  }
};

// const mmenuInit = () => {
//   if (document.getElementById("nav-mobile")) {
//     const navbar = document.getElementById("navbar");
//     const mobileNav = document.getElementById("nav-mobile");

//     const clonedNavbar = navbar.cloneNode(true);
//     clonedNavbar.classList.remove("navbar");
//     mobileNav.appendChild(clonedNavbar);

//     new Mmenu("#nav-mobile", {
//       extensions: ["position-bottom", "fullscreen", "theme-black"],
//       offCanvas: {
//         position: "left",
//         zposition: "front",
//       },
//       theme: "light",
//     });
//   }
// };

const initializeChoices = () => {
  const choicesElements = document.querySelectorAll(".js-choice");

  if (choicesElements.length !== 0) {
    new Choices(".js-choice");
  }
};

document.addEventListener("turbo:load", () => {
  handlePreloader();
  initializeChoices();
});

document.addEventListener("DOMContentLoaded", () => {
  wow.init();
  initializeChoices();
});


