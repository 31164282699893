import { Controller } from "@hotwired/stimulus"

export default class CharacterCounter extends Controller {

  static targets = ["input", "counter"]
  static values = { countdown: Number, mode: { type: String, default: 'char' } }

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {
    this.update()
    this.inputTarget.addEventListener("input", this.update)
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.update)
  }

  update() {
    this.counterTarget.innerHTML = this.count.toString()
  }

  get count(){
    let value = this.counter()

    if (this.hasCountdownValue) {
      value = Math.max(this.countdownValue - value, 0)
    }

    return value
  }

  counter() {
    console.log(this.modeValue)
    switch (this.modeValue) {
      case "word":
        return this.inputTarget.value.split(/\s+/).length
      case "line":
        return this.inputTarget.value.split(/\r\n|\r|\n/).length
      default:
        return this.inputTarget.value.length
    }
  }

  get maxLength() {
    return this.inputTarget.maxLength
  }
}
